import { EventEmitter } from 'eventemitter3';
import { FILTER_EVENT } from './FilterEvent';

export default class AbstractFilter extends EventEmitter {
  constructor(container, index) {
    super();

    this._delays = {
      opened: 150,
      closed: 300,
    };

    this._dom = {};
    this._dom.container = container;
    this._dom.head = this._dom.container.querySelector('.js-filter-head');

    this._index = index;

    this._values = [];
    this._name = null;
    this._type = null;
    this._autoSubmit = false;
    this._preventOpacify = false;
    this._opened = false;
    this._visible = true;

    this._init();
  }

  _init() {
    this._name = this._dom.container.getAttribute('data-name');
    this._type = this._dom.container.getAttribute('data-type');
    this._label = this._dom.container.getAttribute('data-label');

    // Detect Options
    this._fromSidebar = this._dom.container.getAttribute('data-from-sidebar') == 'true';
    this._isFixed = this._dom.container.getAttribute('data-fixed') == 'true';
    this._canOpen = this._dom.container.getAttribute('data-can-open') == 'true';

    // Detect autosubmit
    const autoSubmit = this._dom.container.getAttribute('data-auto-submit');
    if (autoSubmit == 1 || autoSubmit == 'true' || autoSubmit == true) {
      this._autoSubmit = true;
    }

    // Detect prevent opacify
    const preventOpacify = this._dom.container.getAttribute(
      'data-prevent-opacify',
    );
    if (preventOpacify == 1 || preventOpacify == 'true' || preventOpacify == true) {
      this._preventOpacify = true;
    }
  }

  /** Public methods */
  open(e) {
    if (e) {
      e.preventDefault();
    }

    if (this._dom.container.classList.contains('open') || (e && e.target.classList.contains('js-valid'))) {
      if (e && e.target && e.target.closest('.js-filter-head, .js-open-dropdown')) {
        this.close();
      }
      return;
    }

    this._dom.container.classList.add('open');
    this._opened = true;

    if (this._isFixed) {
      const rect = this._dom.head.getBoundingClientRect();
      const width = this._dom.fixed.offsetWidth;

      this._dom.fixed.style.position = 'absolute';
      this._dom.fixed.style.top = rect.height + 'px';
      this._dom.fixed.style.left = 0 + 'px';
      this._dom.fixed.style.zIndex = '10';
      this._dom.fixed.style.width = width + 'px';
      // this._dom.filterbar.style.overflow = 'hidden';
    }

    this.emit(FILTER_EVENT.OPENED, this._name);
  }

  close() {
    // DOM EDIT
    this._dom.container.classList.remove('open');
    this._opened = false;

    if (this._isFixed) {
      this._dom.fixed.style.position = 'relative';
      this._dom.fixed.style.top = null;
      this._dom.fixed.style.left = null;
    }

    // EMIT
    this.emit(FILTER_EVENT.CLOSED, this._name);
  }

  removeAllChoices() {
    console.warn('Method removeAllChoices() must be implemented');
  }

  forceClose() {
    this._dom.container.classList.remove('open');
    this._opened = false;
  }

  setValues(values) {
    console.warn('Method setValues() must be implemented');
  }

  getValues(values) {
    console.warn('Method getValues() must be implemented');
    return this._values;
  }

  getName() {
    return this._name;
  }

  getLabel() {
    return this._label;
  }

  getPreventOpacify() {
    return this._preventOpacify;
  }

  isOpened() {
    return this._opened;
  }

  getObject() {
    return {
      index: this._index,
      name: this._name,
      values: this._values,
      type: this._type,
    };
  }

  isMultiple() {
    console.warn('Method isMultiple() must be implemented');
    return true;
  }

  hide() {
    if (this._visible === false) {
      return;
    }
    this._visible = false;
    this._dom.container.closest('.filter').classList.add('hidden');
  }

  show() {
    if (this._visible === true) {
      return;
    }
    this._visible = true;
    this._dom.container.closest('.filter').classList.remove('hidden');
  }
}
