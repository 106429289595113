import icon from '../../../../helpers/icon';

export const getSingleTemplates = function (template, config, icons) {
  return {
    choice: (classNames, data) => template(`
              <div class="${classNames.item} ${classNames.itemChoice} ${
  data.disabled ? classNames.itemDisabled : classNames.itemSelectable
}" data-select-text="${config.itemSelectText}" data-choice ${
  data.disabled
    ? 'data-choice-disabled aria-disabled="true"'
    : 'data-choice-selectable'
} data-id="${data.id}" data-value="${data.value}" ${
  data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
}>
                ${icons[data.value] ? icon(icons[data.value]) : ''} ${data.label}
              </div>
            `),
    item: (classNames, data) => template(`
          <div class="${classNames.item} ${
  data.highlighted
    ? classNames.highlightedState
    : classNames.itemSelectable
} ${
  data.placeholder ? classNames.placeholder : ''
}" data-item data-id="${data.id}" data-value="${data.value}" ${
  data.active ? 'aria-selected="true"' : ''
} ${data.disabled ? 'aria-disabled="true"' : ''}>
            ${icons[data.value] ? icon(icons[data.value]) : ''} ${data.label}
          </div>
        `),
  };
};
