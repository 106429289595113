import MyChoices from './lib/choices';
import AbstractFilter from '../AbstractFilter';
import { FILTER_EVENT } from '../FilterEvent';
import { getMultipleTemplates } from './templates/multiple-item-template';
import { getSingleTemplates } from './templates/single-templates';

export default class EnhancedSelectFilter extends AbstractFilter {
  constructor(...args) {
    super(...args);
    this._values = [];
    this._multiple = this._dom.container.hasAttribute('multiple') ?? false;
    this._autoSubmit = this._dom.container.hasAttribute('data-auto-submit') ?? false;
    this._search = this._dom.container.hasAttribute('data-search') ?? false;
    this._position = this._dom.container.getAttribute('data-position') ?? 'auto';
    this._widget = null;
    this._initWidget();
  }

  _initWidget() {
    // prepare icons
    const icons = {};
    this._dom.container.querySelectorAll('option').forEach((value) => {
      if (value.getAttribute('data-icon')) {
        icons[value.value] = value.getAttribute('data-icon');
      }
    });
    // init config
    const filter = this;
    // noinspection JSUnusedGlobalSymbols
    const config = {
      position: this._position,
      searchEnabled: this._search,
      searchPlaceholderValue: this._search ? 'Rechercher' : null,
      searchResultLimit: this._search ? 10 : null,
      shouldSort: false,
      itemSelectText: '',
      noChoicesText: 'Vous avez sélectionné toutes les valeurs possibles',
      removeItemButton: this._multiple,
      callbackOnInit: function () {
        const widget = this;
        filter._values = filter.isMultiple() ? widget.getValue(true) : [widget.getValue(true)];
        filter._dom.container.addEventListener('change', () => {
          filter._values = filter.isMultiple() ? widget.getValue(true) : [widget.getValue(true)];
          filter.emit(FILTER_EVENT.CHANGED, { interactive: true });
          if (filter._autoSubmit === true) {
            filter.emit(FILTER_EVENT.AUTO_SUBMIT);
          }
          filter.emit(FILTER_EVENT.CLOSED);
        });
      },
      callbackOnCreateTemplates: function (template) {
        return filter.isMultiple() ?
          getMultipleTemplates(template, this.config, icons) :
          getSingleTemplates(template, this.config, icons);
      },
    };
    // init widget
    this._widget = new MyChoices(this._dom.container, config);
  }

  setValues(values) {
    if (this._multiple) {
      this._widget.setValue(values ?? []);
    } else {
      this._widget.setValue(values[0] ?? null);
    }
  }

  clear() {
    if (this.isMultiple()) {
      this._widget.removeActiveItems();
    } else {
      this._widget.setChoiceByValue('');
    }
    this._values = [];
  }

  reset() {
    this.clear();
    this.emit(FILTER_EVENT.CHANGED, { interactive: false });
  }

  hide() {
    console.log('todo : implement hide ?');
  }

  show() {
    console.log('todo : implement show ?');
  }

  open() {
    this._widget.showDropdown();
  }

  removeAllChoices() {
    this.reset();
  }

  isMultiple() {
    return this._multiple;
  }

  /**
   * Allows to apply a filter to the choices from the outside.
   */
  filterChoices(filterFunction) {
    const newChoices = this._widget.config.choices.filter((choice) => filterFunction(choice) || choice.value === '');
    this._widget.setChoices(newChoices, 'value', 'label', true);
  }

  /**
   * Allows to set current choice from the outside.
   */
  setChoice(value) {
    this._widget.setChoiceByValue(value.toString());
    this._values = this.isMultiple() ? this._widget.getValue(true) : [this._widget.getValue(true)];
    this.emit(FILTER_EVENT.CHANGED, { interactive: false });
  }

  getChoiceLabel() {
    const value = this._widget.getValue();
    if (!value || value.placeholder === true) {
      return null;
    }
    return value.label;
  }
}
