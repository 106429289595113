import flatpickr from 'flatpickr';
import { French } from 'flatpickr/dist/l10n/fr';
import debounce from 'just-debounce-it';
import { FILTER_EVENT } from '../FilterEvent';
import AbstractFilter from '../AbstractFilter';

flatpickr.localize(French);

export default class EnhancedCalendarFilter extends AbstractFilter {
  constructor(...args) {
    super(...args);
    this.Calendar = null;
    this._month = 0;
    this._year = 0;
    this._defaultDate = this._dom.container.value;
    this._isInline = this._dom.container.hasAttribute('data-widget-inline') ?? false;
    if (this._defaultDate) {
      this._values = [this._defaultDate];
    }
    this._autoSubmit = this._dom.container.hasAttribute('data-auto-submit') ?? false;
    this._initCalendar();
  }

  _initCalendar() {
    const config = {
      inline: true,
      minDate: 'today',
      dateFormat: 'd-m-Y',
      defaultDate: this._defaultDate ? this._defaultDate : null,
      disableMobile: true,
      monthSelectorType: 'static',
      onChange: (dateobj, datestr) => {
        this._values = [];
        this._values = [datestr];
        this.emit(FILTER_EVENT.CHANGED, { interactive: true });
        if (this._autoSubmit === true) {
          this.emit(FILTER_EVENT.AUTO_SUBMIT);
        }
        this.emit(FILTER_EVENT.CLOSED);
      },
      onOpen: (selectedDates, dateStr, instance) => {
        if (this._isInline === true) {
          return;
        }
        // handle top / bottom positioning
        const calendarElement = instance.calendarContainer;
        const calendarElementHeight = calendarElement.clientHeight;
        const wrapperElement = calendarElement.closest('.flatpickr-wrapper');
        const wrapperElementTopDistance = wrapperElement.getBoundingClientRect().top;
        if (calendarElementHeight + 170 > wrapperElementTopDistance) {
          calendarElement.classList.add('to-bottom');
        }
      },
      onClose: (selectedDates, dateStr, instance) => {
        if (this._isInline === true) {
          return;
        }
        // reset top / bottom positioning
        const calendarElement = instance.calendarContainer;
        calendarElement.classList.remove('to-bottom');
      },
      onMonthChange: debounce(this._calendarChangedHandler.bind(this), 350),
      onYearChange: debounce(this._calendarChangedHandler.bind(this), 350),
    };
    if (this._isInline === false) {
      config.inline = false;
      config.altInput = true;
      config.altFormat = 'd/m/Y';
      config.altInputClass = 'ct--filter ct--enhanced-calendar form-control flatpickr-input flatpickr-alt-input';
      config.appendTo = this._dom.container.parentElement.querySelector('.js-widget-holder');
      config.static = true;
    }
    this.Calendar = flatpickr(this._dom.container, config);
    this._month = this.Calendar.currentMonth + 1;
    this._year = this.Calendar.currentYear;

    if (this._defaultDate === '' || this._defaultDate === null) {
      const dayOfTheMonth = new Date().getDate();
      if (dayOfTheMonth > 14) {
        this.Calendar.changeMonth(1);
      }
    }
  }

  _calendarChangedHandler() {
    this._month = this.Calendar.currentMonth + 1;
    this._year = this.Calendar.currentYear;
    this.emit(FILTER_EVENT.CALENDAR_MONTH_CHANGED, {
      year: this._year,
      month: this._year,
    });
  }

  setDisabledDates(dates) {
    this.Calendar.set('dateFormat', 'd-m-Y');
    this.Calendar.set('disable', dates);
    this.Calendar.jumpToDate('01-' + this._month + '-' + this._year);
  }

  clearDisabledDates() {
    // this.setDisabledDates([]);
  }

  getYear() {
    return this._year;
  }

  getMonth() {
    return this._month;
  }

  formatSelectedDate(format = 'd/m/Y') {
    const dates = this.Calendar.selectedDates;
    if (dates.length !== 1) {
      return '';
    }
    return flatpickr.formatDate(flatpickr.parseDate(dates[0], 'd-m-Y'), format);
  }

  open() {
    if (this.Calendar && this._isInline === false) {
      this.Calendar.open();
    }
  }

  isMultiple() {
    return false;
  }
}
