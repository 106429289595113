import AbstractFilterManager from './AbstractFilterManager';
import EnhancedCalendarFilter from './enhanced-calendar/enhanced-calendar-filter';
import EnhancedSliderFilter from './enhanced-slider/enhanced-slider-filter';
import EnhancedSelectFilter from './enhanced-select/enhanced-select-filter';

export default class HomePageFilterManager extends AbstractFilterManager {
  _initFilters() {
    for (let i = 0; i < this._dom.filters.length; i++) {
      const el = this._dom.filters[i];
      const type = el.getAttribute('data-type');
      switch (type) {
        case 'enhanced-select':
          this._Filters.push(new EnhancedSelectFilter(el, i));
          break;
        case 'enhanced-calendar':
          this._Filters.push(new EnhancedCalendarFilter(el, i));
          break;
        case 'enhanced-slider':
          this._Filters.push(new EnhancedSliderFilter(el, i));
          break;
        default:
          break;
      }
    }
  }
}
