import navbar from '../components/navbar/navbar.desktop';
import genericslider from '../components/generic-slider/generic-slider.desktop';
import header from '../components/header/header.desktop';
import basket from '../components/basket/basket.desktop';
import basketHeadline from '../components/basket-headline/basketHeadline.desktop';
import logosSlider from '../components/logos-slider/logos-slider.desktop';
import categorySlider from '../components/category-slider/category-slider.desktop';

export default {
  navbar: navbar,
  'generic-slider': genericslider,
  header: header,
  basket: basket,
  basketHeadline: basketHeadline,
  'logos-slider': logosSlider,
  'category-slider': categorySlider,
};
