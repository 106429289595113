import Choices from 'choices.js';

class MyChoices extends Choices {
  _onClick(...args) { // allows selectmultiple to be closed by a click event
    const flag = this._isSelectOneElement;
    this._isSelectOneElement = true;
    super._onClick(...args);
    this._isSelectOneElement = flag;
  }

  _onTouchEnd(event) { // prevent selectmultiple to be shown on touch event, only after on click event
    const flag = this._isSelectMultipleElement;
    this._isSelectMultipleElement = false;
    super._onTouchEnd(event);
    this._isSelectMultipleElement = flag;
  }

  _renderItems() { // allows to add a placeholder to selectmultiple
    super._renderItems();

    if (!this._isSelectMultipleElement) {
      return;
    }

    const activeItems = this._store.activeItems || [];
    if (activeItems.length > 0) {
      return;
    }

    const placeholder = document.createElement('span');
    placeholder.classList.add('choices__placeholder');
    placeholder.innerHTML = 'Indifférent';

    const fragment = document.createDocumentFragment();
    fragment.appendChild(placeholder);

    this.itemList.append(fragment);
  }
}

export default MyChoices;
