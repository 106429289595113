import noUiSlider from 'nouislider';
import wNumb from 'wnumb';
import AbstractFilter from '../AbstractFilter';
import { FILTER_EVENT } from '../FilterEvent';
import onClickOutside from '../../../lib/dom/click-outside';

export default class EnhancedSliderFilter extends AbstractFilter {
  constructor(...args) {
    super(...args);
    this._values = [parseInt(this._dom.container.value)];
    this._getDom();
    this._getConfig();
    this._initRangeSlider();
    this._bindEvents();
  }

  _getDom() {
    // dropdown
    this._dom.dropdown = this._dom.container.closest('.dropdown');
    this._dom.dropdownToggle = this._dom.dropdown.querySelector('.dropdown-toggle');
    this._dom.dropdownMenu = this._dom.dropdown.querySelector('.dropdown-menu');
    // filter
    this._dom.input = this._dom.container;
    this._dom.submit = this._dom.dropdown.querySelector('.filter-submit');
    this._dom.holder = this._dom.dropdown.querySelector('.js-widget-holder');
    this._dom.value = this._dom.dropdownToggle.querySelector('span');
  }

  _getConfig() {
    this._config = {
      min: parseInt(this._dom.input.getAttribute('data-min')),
      max: parseInt(this._dom.input.getAttribute('data-max')),
      step: parseInt(this._dom.input.getAttribute('data-step')),
      homepage: !!this._dom.input.getAttribute('data-specific'),
      default: parseInt(this._dom.input.value),
    };
  }

  _initRangeSlider() {
    const range = {
      min: [this._config.min],
      max: [this._config.max],
    };
    if (this._config.homepage !== null) {
      range['50%'] = [200, 50];
    }
    noUiSlider.create(this._dom.holder, {
      start: [this._config.default],
      step: this._config.step,
      range: range,
      tooltips: [true],
      format: wNumb({
        decimals: 0,
      }),
    });
  }

  _bindEvents() {
    // dropdown toggle event
    this._dom.dropdownToggle.addEventListener('click', () => this.open());
    // submit filter button event
    this._dom.submit.addEventListener('click', () => {
      this._updateValues(this._dom.holder.noUiSlider.get());
      this.close();
    });
    // nouislider change event
    this._dom.holder.noUiSlider.on('change', (values) => {
      this._updateValues(parseInt(values[0]));
    });
  }

  _updateValues(value) {
    this._values = [value];
    this._dom.input.value = value;
    this._dom.value.innerText = value;
    this.emit(FILTER_EVENT.CHANGED, { interactive: true });
  }

  open(e) {
    this._opened = true;
    this._dom.dropdown.classList.add('open');
    // handle top / bottom positioning
    if (this._dom.dropdown.clientHeight + 170 > this._dom.dropdown.getBoundingClientRect().top) {
      this._dom.dropdownMenu.classList.add('to-bottom');
    } else {
      this._dom.dropdownMenu.classList.remove('to-bottom');
    }
    // handle click outside to close dropdown
    onClickOutside(this._dom.dropdown, () => this.close(false));
  }

  close(dispatch = true) {
    this._opened = false;
    this._dom.dropdown.classList.remove('open');
    if (dispatch === true) {
      this.emit(FILTER_EVENT.CLOSED, { interactive: true });
    }
  }

  isMultiple() {
    return false;
  }
}
