import Splide from '@splidejs/splide';
import CategorySlider from './category-slider';

export default class CategorySliderDesktop extends CategorySlider {
  bind() {
    new Splide(this.dom.component, {
      type: 'loop',
      perPage: (window.innerWidth > 1200) ? 3 : 2,
      perMove: 1,
      pagination: false, // disable pagination
      padding: { left: 70, right: 70 },
      gap: 20,
    }).mount();
  }
}
