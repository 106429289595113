import HomePageFilterManager from '../../components/filters/HomePageFilterManager';
import HomepageIndex from './homepage';
import { FILTER_EVENT } from '../../components/filters/FilterEvent';
import { SHOW_DIGITAL_EVENT_MODAL } from '../../messages/generic-messages';

export default class HomepageIndexDesktop extends HomepageIndex {
  constructor(...args) {
    super(...args);
    this._values = null;
    this._getDOM();
    this.FilterManager = new HomePageFilterManager(this.dom.filtersContainer);
    this.FilterManager._changedHandler();
    this._bind();
  }

  _getDOM() {
    this.dom.filtersContainer = document.body.querySelector('.js-filters-container');
    this.dom.form = document.body.querySelector('form');
  }

  _bind() {
    this._handleSearchFilters();
    this._handleSearchBarPosition();
    this._initializeDigitalEventModal();
  }

  _updateFlatpickrAltInputValue() {
    const { date } = this.FilterManager.getNormalizedValues();
    if (!date) {
      return;
    }
    const period = this.FilterManager.getFilterByName('period').getChoiceLabel();
    if (!period) {
      return;
    }
    const input = document.querySelector('.flatpickr-alt-input');
    if (!input) {
      return;
    }
    input.value = `${date.replace(/-/gi, '/')} (${period})`;
  }

  _initializeDigitalEventModal() {
    setTimeout(() => this.dispatcher.emit(SHOW_DIGITAL_EVENT_MODAL), 3000);
  }

  _handleSearchFilters() {
    // on load init flatpickr alt input text
    this._updateFlatpickrAltInputValue();
    // on change update flatpickr alt input text
    this.FilterManager.on(FILTER_EVENT.CHANGED, () => {
      this._updateFlatpickrAltInputValue();
    });
    // handle next filter open
    this.FilterManager.on(FILTER_EVENT.CLOSED, (filterObject) => {
      const nextFilter = this.FilterManager.getByIndex(filterObject.index + 1);
      if (nextFilter && nextFilter.getName() !== 'date') {
        nextFilter.open();
      } else if (nextFilter && nextFilter.getName() === 'date') {
        setTimeout(() => nextFilter.open(), 50);
      }
    });
  }

  _handleSearchBarPosition() {
    const filtersElement = document.querySelector('.section-filter-home');
    const filtersElementOffsetTop = filtersElement.offsetTop;
    const navElement = document.querySelector('.ct--navbar');
    const navElementClientHeight = navElement.clientHeight;
    const headerWrapperElement = document.querySelector('.section-header-wraper');
    const sectionKeyFiguresElement = document.querySelector('.section-key-figures');
    const initialHeaderWrapperElementHeight = headerWrapperElement.clientHeight;
    const initialSectionKeyFiguresPaddingTop = parseInt(window.getComputedStyle(sectionKeyFiguresElement).paddingTop);

    const handleElementsPosition = () => {
      // handle filters wrapper
      if (window.pageYOffset > (filtersElementOffsetTop - navElementClientHeight)) {
        filtersElement.classList.add('fixed');
        sectionKeyFiguresElement.style.paddingTop = initialSectionKeyFiguresPaddingTop + (initialHeaderWrapperElementHeight - headerWrapperElement.clientHeight) + 'px';
      } else {
        filtersElement.classList.remove('fixed');
        sectionKeyFiguresElement.style.removeProperty('padding-top');
      }
    };

    // handle window scroll
    window.onscroll = handleElementsPosition;
    // handle initial scrolled position
    handleElementsPosition();
  }
}
