import { EventEmitter } from 'eventemitter3';
import { pushTrackingEventToDataLayer } from '../../helpers/data-layer-helper';
import { SEARCH_FILTER_CHANGED } from '../../events/data-layer-events';
import { FILTER_EVENT } from './FilterEvent';

export default class AbstractFilterManager extends EventEmitter {
  constructor(container) {
    super();
    this._Filters = [];
    this._values = {};
    this._dom = {};
    this._dom.container = container;
    this._getDom();
    this._initFilters();
    this._initEvents();
  }

  _getDom() {
    this._dom.filters = this._dom.container.querySelectorAll('.ct--filter');
  }

  _initFilters() {
    console.log('Abstract method. To be implemented in child classes.');
  }

  isOpened() {
    for (let i = 0, j = this._Filters.length; i < j; i++) {
      const Filter = this._Filters[i];
      if (Filter.isOpened()) {
        return true;
      }
    }
    return false;
  }

  closeAll() {
    for (let i = 0, j = this._Filters.length; i < j; i++) {
      const Filter = this._Filters[i];
      Filter.forceClose();
    }
  }

  clearAll() {}

  clearTargets(_arrayFilters) {
    for (let i = 0, j = this._Filters.length; i < j; i++) {
      const Filter = this._Filters[i];
      if (_arrayFilters.indexOf(Filter._name) > -1) {
        Filter.removeAllChoices();
      }
    }
  }

  getByIndex(index) {
    return this._Filters.hasOwnProperty(index) ? this._Filters[index] : null;
  }

  openByIndex(index) {
    if (this._Filters.hasOwnProperty(index)) {
      this._Filters[index].open();
    }
  }

  getIndexByName(name) {
    let index = -1;
    for (let i = 0, j = this._Filters.length; i < j; i++) {
      const Filter = this._Filters[i];
      if (Filter.getName() === name) {
        index = i;
        break;
      }
    }
    return index;
  }

  getFilterByName(name) {
    for (let i = 0, j = this._Filters.length; i < j; i++) {
      const Filter = this._Filters[i];
      if (Filter.getName() === name) {
        return Filter;
      }
    }
    return null;
  }

  _initEvents() {
    this._bindFiltersLoop((Filter) => {
      Filter.on(
        FILTER_EVENT.CHANGED,
        this._changedHandler.bind(this, Filter.getName(), Filter.getLabel()),
      );
    });

    this._bindFiltersLoop((Filter) => {
      Filter.on(FILTER_EVENT.OPENED, this._openedHandler.bind(this));
    });

    this._bindFiltersLoop((Filter) => {
      Filter.on(
        FILTER_EVENT.AUTO_SUBMIT,
        this.emit.bind(this, FILTER_EVENT.AUTO_SUBMIT),
      );
    });

    this._bindFiltersLoop((Filter) => {
      Filter.on(
        FILTER_EVENT.DISPLAY_VALUE,
        this._displayValueHandler.bind(this, Filter),
      );
    });

    this._bindFiltersLoop((Filter) => {
      Filter.on(FILTER_EVENT.CLOSED, () => {
        this.emit(FILTER_EVENT.CLOSED, Filter.getObject());
      });
    });
  }

  _changedHandler(filterName, filterLabel, eventPayload) {
    this._values = {};
    this.emit(FILTER_EVENT.PRE_COMPUTE, this._values, filterName);
    this._bindFiltersLoop((Filter) => {
      const obj = Filter.getObject();
      this._values[obj.name] = obj.values;
    });
    if (filterName !== 'page' && typeof this._values['page'] !== 'undefined') { // reset page number when filters change
      this._values['page'] = [1];
    }
    this.emit(FILTER_EVENT.CHANGED, this._values, filterName);
    if (eventPayload && eventPayload.interactive === true) {
      pushTrackingEventToDataLayer(SEARCH_FILTER_CHANGED, { name: filterName, label: filterLabel });
    }
  }

  _openedHandler(name) {
    this._bindFiltersLoop((Filter) => {
      if (Filter.getName() != name && Filter.isOpened() === true) {
        Filter.close();
      }
    });
    const Filter = this.getFilterByName(name);
    this.emit(FILTER_EVENT.OPENED, Filter);
  }

  _displayValueHandler(Filter) {
    this.emit(FILTER_EVENT.DISPLAY_VALUE, Filter);
  }

  _bindFiltersLoop(callback) {
    for (let i = 0, j = this._Filters.length; i < j; i++) {
      const Filter = this._Filters[i];
      callback(Filter);
    }
  }

  getValues() {
    // this._changedHandler();
    return this._values;
  }

  getNormalizedValues() {
    const rawValues = this._values;
    const values = {};

    Object.keys(rawValues).forEach((filterName) => {
      if (this.getFilterByName(filterName).isMultiple() === false) {
        values[filterName] = rawValues[filterName][0] || null;
      } else {
        values[filterName] = rawValues[filterName];
      }
    });

    return values;
  }
}
